import React from 'react';
import { Checkbox as MaterialCheckbox } from '@material-ui/core';
import { Container } from './styles';

interface CheckboxProps {
  label: string;
  checked: boolean;
  onClick(value: boolean): void;
}

export function Checkbox({ label, checked, onClick }: CheckboxProps) {
  function handleChangeState() {
    onClick(!checked);
  }

  return (
    <Container onClick={handleChangeState}>
      <MaterialCheckbox checked={checked} onChange={handleChangeState} />
      <label>{label}</label>
    </Container>
  );
}
