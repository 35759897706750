import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  height: 50px;

  background-color: var(--primary);
  color: var(--background);

  border-radius: 0.25rem;
  border: 0;

  transition: filter 0.2s;

  font-size: 1rem;
  font-weight: 500;

  &:hover {
    filter: brightness(0.9);
  }
`;
