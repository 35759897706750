import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { ExameCard } from '../../components/ExameCard';
import Input from '../../components/Input';
import { useAuth } from '../../hooks/auth';
import { useLoader } from '../../hooks/loading';
import { apiHnsn, apiLaureano, apiLaureanoSoul } from '../../services/api';
import {
  Container,
  Form,
  Header,
  HeaderButton,
  Imagem,
  List,
  Logo,
  User,
  UserGretting,
  UserInfo,
} from './styles';

import logo from '../../assets/CEDAPP_ICONE.png';

interface LaudoExame {
  cdLaudo: string;
  cdPedRx: string;
  nmPaciente: string;
  dtLaudo: string;
  dsExame: string;
  nrControle: string;
  from: 'LAUREANO' | 'HNSN' | 'LAUREANO_MV2000';
  status: string;
}

export function Dashboard() {
  const [filtro, setFiltro] = useState('');

  const { usuarioHnsn, usuarioLaureano, usuarioLaureanoSoul, signOut } =
    useAuth();
  const { showLoader, hideLoader } = useLoader();
  const [laudos, setLaudos] = useState([] as LaudoExame[]);

  const [isPaciente, setIsPaciente] = useState(false);

  const loadData = useCallback(async () => {
    showLoader();
    var listFinalLaudos: LaudoExame[] = [];

    if (usuarioLaureano) {
      if (!usuarioLaureano.cdPrestadorArray) {
        const { data } = await apiLaureano.get('/paciente');
        listFinalLaudos.push(...data.slice(0, 100));

        setIsPaciente(true);
      } else {
        var { data } = await apiLaureano.get(
          `/medico/${usuarioLaureano.cdPrestadorArray}`,
        );
        listFinalLaudos.push(...data.slice(0, 100));
      }
    }

    if (usuarioLaureanoSoul) {
      if (!usuarioLaureanoSoul.cdPrestadorArray) {
        await apiLaureanoSoul.get('/paciente').then(({ data }) => {
          listFinalLaudos.push(...data.slice(0, 100));
        });

        setIsPaciente(true);
      } else {
        await apiLaureanoSoul
          .get(`/medico/${usuarioLaureanoSoul.cdPrestadorArray}`)
          .then(({ data }) => {
            listFinalLaudos.push(...data.slice(0, 100));
          });
      }
    }

    if (usuarioHnsn) {
      if (!usuarioHnsn.cdPrestadorArray) {
        const { data } = await apiHnsn.get('/paciente');

        listFinalLaudos.push(...data.slice(0, 200));

        setIsPaciente(true);
      } else {
        const { data } = await apiHnsn.get(
          `/medico/${usuarioHnsn.cdPrestadorArray}`,
        );
        listFinalLaudos.push(...data.slice(0, 200));
      }
    }

    listFinalLaudos = listFinalLaudos.sort((a: LaudoExame, b: LaudoExame) => {
      let datePart1: any
      let datePart2: any
      if (a.dtLaudo != null) {
        datePart1 = a.dtLaudo.split('/');
        new Date(+datePart1[2], +datePart1[1] - 1, +datePart1[0]).getTime()
      }
      if (b.dtLaudo != null) {
        datePart2 = b.dtLaudo.split('/');
        new Date(+datePart2[2], +datePart2[1] - 1, +datePart2[0]).getTime()
      }

      return (
        datePart1 - datePart2
      );
    });


    for (let i = 0; i < listFinalLaudos.length; i++) {
      if (listFinalLaudos[i].status === "EM ANDAMENTO") {
        const nrControle = listFinalLaudos[i].nrControle;
        for (let j = i; j < listFinalLaudos.length; j++) {
          if (nrControle === listFinalLaudos[j].nrControle && listFinalLaudos[j].status === "CONCLUIDO") {
            listFinalLaudos.splice(j, 1);
            continue;
          }
        }
      }
    }

    setLaudos(listFinalLaudos);
  }, [usuarioHnsn, usuarioLaureano, usuarioLaureanoSoul, showLoader]);

  useEffect(() => {
    loadData().finally(hideLoader);
  }, [loadData, hideLoader]);

  async function handleBuscarDados() {
    showLoader();

    var listFinalLaudos: LaudoExame[] = [];
    if (usuarioLaureano) {
      if (usuarioLaureano.cdPrestadorArray) {
        const { data } = await apiLaureano.get(
          `/medico/${usuarioLaureano.cdPrestadorArray
          }?filtro=${filtro?.toUpperCase()}`,
        );
        listFinalLaudos.push(...data.slice(0, 200));
      }
    }

    if (usuarioLaureanoSoul) {
      if (usuarioLaureanoSoul.cdPrestadorArray) {
        await apiLaureanoSoul
          .get(
            `/medico/${usuarioLaureanoSoul.cdPrestadorArray
            }?filtro=${filtro?.toUpperCase()}`,
          )
          .then(({ data }) => listFinalLaudos.push(...data.slice(0, 100)));
      }
    }

    if (usuarioHnsn) {
      if (usuarioHnsn.cdPrestadorArray) {
        const { data } = await apiHnsn.get(
          `/medico/${usuarioHnsn.cdPrestadorArray
          }?filtro=${filtro?.toUpperCase()}`,
        );
        listFinalLaudos.push(...data.slice(0, 200));
      }
    }

    listFinalLaudos = listFinalLaudos.sort((a: LaudoExame, b: LaudoExame) => {
      let datePart1: any
      let datePart2: any
      if (a.dtLaudo != null) {
        datePart1 = a.dtLaudo.split('/');
        new Date(+datePart1[2], +datePart1[1] - 1, +datePart1[0]).getTime()
      }
      if (b.dtLaudo != null) {
        datePart2 = b.dtLaudo.split('/');
        new Date(+datePart2[2], +datePart2[1] - 1, +datePart2[0]).getTime()
      }

      return (
        datePart1 - datePart2
      );
    });

    for (let i = 0; i < listFinalLaudos.length; i++) {
      if (listFinalLaudos[i].status === "EM ANDAMENTO") {
        const nrControle = listFinalLaudos[i].nrControle;
        for (let j = i; j < listFinalLaudos.length; j++) {
          if (nrControle === listFinalLaudos[j].nrControle && listFinalLaudos[j].status === "CONCLUIDO") {
            listFinalLaudos.splice(j, 1);
            continue;
          }
        }
      }
    }
    setLaudos(listFinalLaudos);

    hideLoader();
  }

  return (
    <Container>
      <Header>
        <Imagem>
          <Logo src={logo} alt="CEDAPP" />
        </Imagem>

        <UserInfo>
          <User>
            <UserGretting>Bem-vindo,</UserGretting>

            {usuarioLaureano ? (
              <h1>{usuarioLaureano.nome}</h1>
            ) : usuarioLaureanoSoul ? (
              <h1>{usuarioLaureanoSoul.nome}</h1>
            ) : (
              <h1>{usuarioHnsn?.nome}</h1>
            )}
          </User>
        </UserInfo>
        <HeaderButton>
          <Button onClick={signOut}>SAIR</Button>
        </HeaderButton>
      </Header>

      {!isPaciente && (
        <Form>
          <Input
            onChange={(event) => setFiltro(event.target.value)}
            placeholder="Filtrar exames"
            value={filtro}
          />

          <Button onClick={handleBuscarDados}>Procurar</Button>
        </Form>
      )}

      <List>
        {laudos.map((item, index) => {
          return (
            <ExameCard
              key={index}
              cdLaudo={item.cdLaudo}
              cdPedRx={item.cdPedRx}
              nmPaciente={item.nmPaciente}
              dtLaudo={item.dtLaudo}
              dsExame={item.dsExame}
              nrControle={item.nrControle}
              from={item.from}
              status={item.status}
            />
          );
        })}
      </List>
    </Container>
  );
}
