import React from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import { Dashboard } from '../pages/Dashboard';
import { SignIn } from '../pages/SignIn';
import LaudoView from '../pages/laudo';

interface RouteProps extends ReactDOMRouteProps {
  path: string;
  exact: boolean;
  isPrivate?: boolean;
  component: React.ComponentType;
}

export function Routes() {
  const { usuarioLaureano, usuarioHnsn, usuarioLaureanoSoul } = useAuth();

  const PrivateRoute = ({ component: Component, ...rest }: RouteProps) => (
    <Route
      {...rest}
      render={(props: any) =>
        usuarioLaureano || usuarioHnsn || usuarioLaureanoSoul ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );

  return (
    <Switch>
      <Route path="/login" component={SignIn} />
      <PrivateRoute path="/home" exact component={Dashboard} isPrivate />
      {/* <PrivateRoute path="/laudo/:cdLaudo" exact component={LaudoView} /> */}
      <Route path="/laudo/:cdLaudo" exact component={LaudoView} />
      <Redirect from="/" to={{ pathname: '/login' }} />
    </Switch>
  );
}
