import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;

  background-color: var(--background);

  padding: 0 30px;
`;

export const Title = styled.h1`
  font-size: 20px;
  color: var(--primary);

  font-weight: bold;

  margin: 30px 0 24px;
`;

export const Logo = styled.img`
  height: 150px;
`;

export const Content = styled.div`
  width: 300px;
`;
