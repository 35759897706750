import React from "react";

import { AuthProvider } from "./auth";
import { LoaderProvider } from "./loading";

const AppProvider: React.FC = ({ children }) => (
  <LoaderProvider>
    <AuthProvider>{children}</AuthProvider>
  </LoaderProvider>
);

export default AppProvider;
