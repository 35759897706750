import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 3px;
  margin-bottom: 3px;

  width: 100%;

  input {
    width: 100%;
    padding: 0 1.5rem;

    height: 50px;

    border-radius: 0.25rem;
    border: 1px solid var(--primary);
    background: var(--background);
    color: var(--primary);
    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--primary);
    }

    & + input {
      margin-top: 1rem;
    }
  }
`;
