import axios from 'axios';


const apiLaureano = axios.create({
  baseURL: 'https://cedap.hlaureano.org.br:9090',
});

const apiLaureanoSoul = axios.create({
  baseURL: 'https://cedap.hlaureano.org.br:9191',
});

const apiHnsn = axios.create({
  baseURL: 'https://vpn.hnsn.com.br:8284',
});

export { apiHnsn, apiLaureano, apiLaureanoSoul };
