import React, {
  useEffect,
  createContext,
  useCallback,
  useState,
  useContext,
} from 'react';
import { apiLaureano, apiHnsn, apiLaureanoSoul } from '../services/api';
// import { useFullPageLoader } from "./loader";

interface AuthContextData {
  usuarioHnsn?: Usuario;
  usuarioLaureano?: Usuario;
  usuarioLaureanoSoul?: Usuario;
  signInLaureanoSoul(credentials: SignInCredentials): Promise<any>;
  signInLaureano(credentials: SignInCredentials): Promise<any>;
  signInHnsn(credentials: SignInCredentials): Promise<any>;
  signOut(): void;
  setData(state: AuthState): Promise<void>;
  tokenLaureano: string | null;
  tokenHnsn: string | null;
  tokenLaureanoSoul: string | null;
  loadStorageData(): Promise<void>;
}

interface SignInCredentials {
  login: string;
  password: string;
  isMedico: boolean;
}

interface AuthState {
  tokenLaureano: string | null;
  tokenLaureanoSoul: string | null;
  tokenHnsn: string | null;
  usuarioLaureano: any;
  usuarioLaureanoSoul: any;
  usuarioHnsn: any;
}

interface Usuario {
  id: number;
  nome: string;
  crm?: string;
  cpf?: string;
  dtNascimento: string;
  cdPrestadorArray?: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>({} as AuthState);

  useEffect(() => {
    loadStorageData();
  }, []);

  async function loadStorageData(): Promise<void> {
    const tokenLaureano = localStorage.getItem('@cedapp:tokenLaureano');
    const usuarioLaureano = localStorage.getItem('@cedapp:userLaureano');
    const tokenLaureanoSoul = localStorage.getItem('@cedapp:tokenLaureanoSoul');
    const usuarioLaureanoSoul = localStorage.getItem(
      '@cedapp:userLaureanoSoul',
    );
    const usuarioHnsn = localStorage.getItem('@cedapp:userHnsn');
    const tokenHnsn = localStorage.getItem('@cedapp:tokenHnsn');

    if (usuarioLaureano || usuarioHnsn || usuarioLaureanoSoul) {
      apiLaureanoSoul.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${tokenLaureanoSoul}`;

      apiLaureano.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${tokenLaureano}`;

      apiHnsn.defaults.headers.common['Authorization'] = `Bearer ${tokenHnsn}`;

      setData({
        tokenHnsn,
        tokenLaureano,
        tokenLaureanoSoul,
        usuarioLaureano: JSON.parse(usuarioLaureano as string),
        usuarioLaureanoSoul: JSON.parse(usuarioLaureanoSoul as string),
        usuarioHnsn: JSON.parse(usuarioHnsn as string),
      });
    }
  }

  const signInLaureano = useCallback(async ({ login, password, isMedico }) => {
    await apiLaureano
      .post('/session', {
        login,
        password,
        isMedico,
      })
      .then(async (response) => {
        var { token, user } = response.data;

        localStorage.setItem('@cedapp:tokenLaureano', token);
        localStorage.setItem('@cedapp:userLaureano', JSON.stringify(user));

        apiLaureano.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${token}`;
      })
      .catch((err) => console.error(err));
  }, []);

  const signInLaureanoSoul = useCallback(
    async ({ login, password, isMedico }) => {
      await apiLaureanoSoul
        .post('/session', {
          login,
          password,
          isMedico,
        })
        .then(async (response) => {
          var { token, user } = response.data;

          localStorage.setItem('@cedapp:tokenLaureanoSoul', token);
          localStorage.setItem(
            '@cedapp:userLaureanoSoul',
            JSON.stringify(user),
          );

          apiLaureanoSoul.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${token}`;
        })
        .catch((err) => console.error(err));
    },
    [],
  );

  const signInHnsn = useCallback(async ({ login, password, isMedico }) => {
    await apiHnsn
      .post('/session', {
        login,
        password,
        isMedico,
      })
      .then(async (response) => {
        var { token, user } = response.data;

        localStorage.setItem('@cedapp:tokenHnsn', token);
        localStorage.setItem('@cedapp:userHnsn', JSON.stringify(user));

        apiHnsn.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      })
      .catch((err) => console.error(err));
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem('@cedapp:tokenLaureano');
    localStorage.removeItem('@cedapp:userLaureano');

    localStorage.removeItem('@cedapp:tokenLaureanoSoul');
    localStorage.removeItem('@cedapp:userLaureanoSoul');

    localStorage.removeItem('@cedapp:tokenHnsn');
    localStorage.removeItem('@cedapp:userHnsn');

    setData({} as AuthState);
  }, []);

  const setDataAuth = useCallback(async (data: AuthState) => {
    setData(data);
  }, []);
  return (
    <AuthContext.Provider
      value={{
        usuarioHnsn: data.usuarioHnsn,
        usuarioLaureano: data.usuarioLaureano,
        usuarioLaureanoSoul: data.usuarioLaureanoSoul,
        signInLaureano,
        signInHnsn,
        signInLaureanoSoul,
        signOut,
        setData: setDataAuth,
        tokenLaureano: data.tokenLaureano,
        tokenLaureanoSoul: data.tokenLaureanoSoul,
        tokenHnsn: data.tokenHnsn,
        loadStorageData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
