import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root{
    --primary: #007467;
    --secondary: #00A79D;
    --third: #9FCC3B; 
    --success: #12A454;
    --success_light: rgba(18,164,84, 0.5);
    --attention: #e83f5b;
    --attention_light: rgba(232,63,91, 0.5); 
    --background: #ffff;  
   --backgroundCard: #E5E5E5;
    --text: #ffff; 
  }

  * {
    margin:0;
    padding: 0;
    outline:0;
    box-sizing: border-box;

  }

  button {
    cursor: pointer
  }

  body{
    -webkit-font-smoothing: antialiased;
    background: var(--background);
  }

  body, input, textarea, button{
    font-family: "Mont Serrat", sans-serif;
    font-weight: 400;
  }

  h1, h2, h3, h4, strong {
    font-weight: 600;
  }
  
`;
