import React from 'react';

import pdfImage from '../../assets/pdf.png';

import {
  Button,
  Container,
  Info,
  Infos,
  NomePaciente,
  Photo,
  Row,
  StatusAguardando,
  StatusConcluido,
  TextStatus,
} from './styles';
interface LaudoExame {
  cdLaudo: string;
  cdPedRx: string;
  nmPaciente: string;
  dtLaudo: string;
  dsExame: string;
  nrControle: string;
  from: 'LAUREANO' | 'HNSN' | 'LAUREANO_MV2000';
  status: string;
}
export function ExameCard({
  cdLaudo,
  nmPaciente,
  dsExame,
  dtLaudo,
  nrControle,
  from,
  status,
}: LaudoExame) {
  async function handleNavigateTolLaudoViewer() {
    localStorage.setItem('@cedapp:from', from);
    const url = `/laudo/${cdLaudo}`;
    window.open(url, '_blank');
  }

  return (
    <Container>
      <NomePaciente>{nmPaciente}</NomePaciente>

      <Row>
        <Infos>
          <Info>{nrControle}</Info>
          <Info>{dtLaudo}</Info>

          <Info>{dsExame}</Info>
          {status === 'CONCLUIDO' && (
            <StatusConcluido>
              <TextStatus>Concluído</TextStatus>
            </StatusConcluido>
          )}
          {status === 'CONCLUÍDO' && (
            <StatusConcluido>
              <TextStatus>Concluído</TextStatus>
            </StatusConcluido>
          )}
          {status === undefined && (
            <StatusAguardando>
              <TextStatus>Em Andamento</TextStatus>
            </StatusAguardando>
          )}
          {status === 'EM ANDAMENTO' && (
            <StatusAguardando>
              <TextStatus>Em Andamento</TextStatus>
            </StatusAguardando>
          )}
        </Infos>

        {status === 'CONCLUIDO' && (
          <Button onClick={handleNavigateTolLaudoViewer}>
            <Photo src={pdfImage} />
          </Button>
        )}
        {status === 'CONCLUÍDO' && (
          <Button onClick={handleNavigateTolLaudoViewer}>
            <Photo src={pdfImage} />
          </Button>
        )}
      </Row>
    </Container>
  );
}
