import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;
  justify-content: center;

  background-color: var(--background);
`;

export const Header = styled.div`
  height: 80px;

  background-color: var(--primary);

  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    color: var(--text);
    font-size: 24px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  align-self: center;

  padding: 10px;

  width: 500px;
`;

export const List = styled.div`
  padding: 15px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;

  margin-top: 20px;
  margin-bottom: 50px;

  list-style: none;

  width: 100%;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Imagem = styled.div`
  width: 55px;
  height: 55px;

  display: flex;

  align-items: center;
  justify-content: center;

  background: var(--background);

  border-radius: 10px;

  margin-right: 5px;
`;

export const Logo = styled.img`
  width: 50px;
  height: 50px;
`;

export const UserInfo = styled.div`
  flex-direction: row;
  align-items: center;
`;
export const User = styled.div``;
export const UserGretting = styled.strong`
  color: var(--secondary);

  font-size: 18px;
  font-weight: normal;
`;

export const HeaderButton = styled.div`
  width: 100px;
  position: absolute;

  right: 0;
`;
