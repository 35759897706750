import React from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import { Container } from './styles';

interface InputPropsA extends InputProps {
  autoComplete?: string;
  mask: string;
}

const Input: React.FC<InputPropsA> = ({ mask, ...rest }, ref) => {
  return (
    <Container>
      <ReactInputMask mask={mask} {...rest} />
    </Container>
  );
};

export default Input;
