import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
  border-radius: 10px;

  background: var(--backgroundCard);
  margin-bottom: 10px;
`;
export const NomePaciente = styled.h1`
  color: var(--primary);

  font-size: 16px;
  font-weight: bold;

  margin-bottom: 10px;
`;
export const Info = styled.h1`
  color: var(--text);

  font-size: 14px;
  font-weight: bold;
`;

export const StatusConcluido = styled.div`
  margin-top: 5%;
  width: 45%;
  height: 15;
  background-color: green;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;
export const StatusAguardando = styled.div`
  margin-top: 5%;
  width: 60%;
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

export const TextStatus = styled.p`
font-size: 14px;
  font-weight: bold;
  color: #fff;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Button = styled.button`
  width: 20%;
  height: 60px;

  border: 0;
  border-radius: 8px;
`;

export const Photo = styled.img`
  width: 100%;
  height: 60px;

  border-radius: 10px;
`;

export const Infos = styled.div`
  width: 80%;
`;
