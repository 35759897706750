import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  position: fixed;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f8f8f8ad;

  z-index: 5000;
`;
