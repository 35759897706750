import React, { useState } from 'react';

import { Button } from '../../components/Button';
import Input from '../../components/Input';

import logoImg from '../../assets/CEDAPP-CRM.png';
import { Checkbox } from '../../components/Checkbox';
import { useAuth } from '../../hooks/auth';
import InputMask from '../../components/InputMask';
import { apiHnsn, apiLaureano, apiLaureanoSoul } from '../../services/api';

import { Container, Content, Logo, Title } from './styles';
import { useLoader } from '../../hooks/loading';
import { useHistory } from 'react-router-dom';

export function SignIn() {
  const history = useHistory();
  const [loginMedico, setLoginMedico] = useState(true);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const { loadStorageData } = useAuth();

  const { showLoader, hideLoader } = useLoader();

  async function handleSignIn() {
    if (!login || !password) {
      alert('Preencha os dados para fazer login!');
      return;
    }

    showLoader();

    try {
      const response = await apiLaureano.post('/session', {
        login,
        password: loginMedico ? password.replace(/\D/gim, '') : password,
        isMedico: loginMedico,
      });

      const { token, user } = response.data;

      localStorage.setItem('@cedapp:tokenLaureano', token);
      localStorage.setItem('@cedapp:userLaureano', JSON.stringify(user));

      apiLaureano.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } catch (error) {
      console.log(error);
    }

    try {
      const responseHnsn = await apiHnsn.post('/session', {
        login,
        password: loginMedico ? password.replace(/\D/gim, '') : password,
        isMedico: loginMedico,
      });
      const { token, user } = responseHnsn.data;

      localStorage.setItem('@cedapp:tokenHnsn', token);
      localStorage.setItem('@cedapp:userHnsn', JSON.stringify(user));

      apiHnsn.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } catch (error) {
      console.log(error);
    }

    try {
      const responseLaureanoSoul = await apiLaureanoSoul.post('/session', {
        login,
        password: loginMedico ? password.replace(/\D/gim, '') : password,
        isMedico: loginMedico,
      });
      const { token, user } = responseLaureanoSoul.data;

      localStorage.setItem('@cedapp:tokenLaureanoSoul', token);
      localStorage.setItem('@cedapp:userLaureanoSoul', JSON.stringify(user));

      apiLaureanoSoul.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${token}`;
    } catch (error) {
      console.log(error);
    }

    hideLoader();

    await loadStorageData();

    history.push('/home');
  }

  return (
    <Container>
      <Logo src={logoImg} alt="CEDAPP" />

      <div>
        <Title>Faça seu login</Title>
      </div>

      <Content>
        <div>
          <Checkbox
            onClick={(checked) => setLoginMedico(checked)}
            label={'Acesso médico'}
            checked={loginMedico}
          />
          <Checkbox
            onClick={(checked) => setLoginMedico(!checked)}
            label="Acesso paciente"
            checked={!loginMedico}
          />
        </div>

        {loginMedico ? (
          <>
            <Input
              onChange={(event) => setLogin(event.target.value)}
              placeholder="CRM"
              value={login}
            />
            <InputMask
              onChange={(event) => setPassword(event.target.value)}
              value={password}
              placeholder="CPF"
              mask="999.999.999-99"
            />
          </>
        ) : (
          <>
            <InputMask
              onChange={(event) => setLogin(event.target.value)}
              value={login}
              placeholder="CPF"
              mask="999.999.999-99"
            />
            <InputMask
              onChange={(event) => setPassword(event.target.value)}
              value={password}
              placeholder="Data de nascimento"
              mask="99/99/9999"
            />
          </>
        )}

        <Button onClick={handleSignIn}>Entrar</Button>
      </Content>
    </Container>
  );
}
